import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { routing } from './app.routing';
import { AppConfig } from './app.config';

import { AppComponent } from './app.component';
import { ErrorComponent } from './pages/error/error.component';
import { AngularTokenService } from 'angular-token';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AngularTokenModule } from 'angular-token';

import { UserService } from './services/user.service';
import { AccountService } from './services/account.service';
import { ApiErrorService } from './services/api-error.service';
import { TemplateService } from './services/template.service';
import { CardService } from './services/card.service';

import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { DialogService } from './services/dialog.service';
import { NgSelectModule } from '@ng-select/ng-select';

import { CustomerService } from './services/customer.service';
import { WindowRef } from './services/window-ref';
import { SearchService } from './services/search-service';
import { ReminderService } from './services/reminder.service';
import { InviteService } from './services/invite.service';
import { NavService } from './services/nav-service.service';
import {
  NgxAwesomePopupModule,
  DialogConfigModule,
  ConfirmBoxConfigModule,
  ToastNotificationConfigModule
} from '@costlydeveloper/ngx-awesome-popup';
import { environment } from '../environments/environment';
import { MixpanelService } from './services/mixpanel-service';
import { UserResolver } from './resolvers/user-resolver';
import { IdleService } from './services/idle.service';
import { DashboardService } from './services/dashboard.service';
import { LinkifyService } from './services/linkify.service';
import { NgxSpinnerModule } from "ngx-spinner";

// hack to fix build issues for finding correct Typings
import * as bootstrap from 'bootstrap';
import * as $ from 'jquery';


@NgModule({
  declarations: [AppComponent, ErrorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxAwesomePopupModule.forRoot(), // Essential, mandatory main module.
    DialogConfigModule.forRoot(), // Needed for instantiating dynamic components.
    ConfirmBoxConfigModule.forRoot(), // Needed for instantiating confirm boxes.
    ToastNotificationConfigModule.forRoot(), // Needed for instantiating toast notifications
    NgxAwesomePopupModule.forRoot({
      colorList: {
        primary: '#28c37a', // optional
        secondary: '#989ea5', // optional
        info: '#015cb2', // optional
        success: '#2d922d', // optional
        warning: '#f79a17', // optional
        danger: '#d20011', // optional
        light: '#fbfbfb', // optional
        dark: '#343a40', // optional
        customOne: '#28c37a', // optional - checkwiz green
        customTwo: '#343f40' // optional (up to custom five)
      }
    }),
    AngularTokenModule.forRoot(environment.token_auth_config),
    ToastrModule.forRoot({
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true
    }),
    NgSelectModule,
    NgxSpinnerModule,
    routing
  ],
  providers: [
    { provide: Window, useValue: window },
    AppConfig,
    AngularTokenService,
    UserService,
    UserResolver,
    InviteService,
    AccountService,
    ApiErrorService,
    TemplateService,
    CardService,
    CanDeactivateGuard,
    DialogService,
    CustomerService,
    WindowRef,
    SearchService,
    ReminderService,
    NavService,
    MixpanelService,
    IdleService,
    DashboardService,
    LinkifyService,
    {
      provide: APP_INITIALIZER,
      useValue: () =>  new Promise(resolve =>
        setTimeout(resolve, 500)
      ),
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
