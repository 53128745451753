import { Injectable } from '@angular/core';
import linkifyStr from "linkify-string";

@Injectable({
  providedIn: 'root'
})
export class LinkifyService {

  constructor() { }

  processText(htmlString: string) {
    if (!htmlString) return null;
    let options = {
      validate: {
        url: (value) => /^https?:\/\//.test(value)
      },
      nl2br: true,
      target: {
        url: "_blank",
      }
    }
    return linkifyStr(htmlString, options);
  }
}
