import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Account } from '../types/account';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AngularTokenService } from 'angular-token';
import { UserService } from './user.service';
import { User } from '../types/user';

@Injectable()
export class AccountService {
  private url = `${environment.token_auth_config.apiBase}/accounts`;

  constructor(
    private http: HttpClient,
    private authService: AngularTokenService,
    private userService: UserService
  ) { }

  patchAccount(account: Account): Observable<Account> {
    return this.http.patch(`${this.url}/${account.id}`, account).pipe(
      map((response: HttpResponse<Account>) => {
        // we need to refresh the user data to get update info about the account and whether they are over their limit
        // also, this saves callers to patchAccount from having to update the user data themselves with the new account info
        this.authService.validateToken().subscribe((result) => {
          console.log('patchAccount: validateToken result: ', result);
          this.userService.setCurrentUser(result.data as User);
        });
        return (response as unknown) as Account;
      })
    );
  }
}
