import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class NavService {
  private pageTitle = new BehaviorSubject<string>('');
  private showSearch = new BehaviorSubject<boolean>(true);

  constructor() {}

  public get getPageTitle(): Observable<string> {
    return this.pageTitle.asObservable();
  }

  public setPageTitle(value: string): void {
    this.pageTitle.next(value);
  }

  public get getShowSearch(): Observable<boolean> {
    return this.showSearch.asObservable();
  }

  public setShowSearch(value: boolean): void {
    this.showSearch.next(value);
  }
}
