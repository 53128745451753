import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../types/customer';
import { Mail } from '../types/mail';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class CustomerService {
  private url = `${environment.token_auth_config.apiBase}/customers`;

  constructor(private http: HttpClient) {}

  postCustomer(customer: Customer): Observable<Customer> {
    return this.http.post(this.url, customer).pipe(
      map(response => {
        return response['customer'];
      })
    );
  }

  patchCustomer(customer: Customer): Observable<Customer> {
    return this.http.patch(`${this.url}/${customer.id}`, customer).pipe(
      map(response => {
        return response['customer'];
      })
    );
  }

  postSendEmail(customer: Customer, template_id: string, mail: Mail): Observable<any> {
    return this.http
      .post(`${this.url}/${customer.id}/email_checklist`, { customer: { mail: mail, template_id: template_id } })
      .pipe(
        map(response => {
          return response;
        })
      );
  }
}
