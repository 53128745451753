import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import { ToastrService } from 'ngx-toastr';
import { User } from './types/user';
import { MixpanelService } from './services/mixpanel-service';

@Component({
  selector: 'az-root',
  encapsulation: ViewEncapsulation.None,
  template: `
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AngularTokenService,
    private userService: UserService,
    private _router: Router,
    private _toastrService: ToastrService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.closeTooltips();
    this.clearStorage();
    const token = localStorage.getItem('accessToken');
    if (token) this.tryLogin();
  }

  private clearStorage(): void {
    Object.keys(localStorage)
    .filter(x =>
        x.startsWith('wip'))
    .forEach(x =>
      localStorage.removeItem(x))
  }

  private closeTooltips(): void {
    $(document).on('show.bs.tooltip', function(e) {
      setTimeout(function() {
        // due to a bug of tooltips getting stuck, this ensure after a 5s delay they are all hidden
        $('[data-toggle="tooltip"]').tooltip('hide');
      }, 2000);
    });
  }

  private tryLogin(): void {
    this.authService.validateToken().subscribe(
      result => {
        console.log('tryLogin result is', result);
        const user = result.data as User;
        this.userService.setCurrentUser(user);
        this.mixpanelService.identify(`${user.uid}`);
        this.mixpanelService.setPeople({
          $name: `${user.name}`,
          $email: `${user.email}`,
          Account: `${user.account.name}`
        });
      },
      error => {
        this._toastrService.error('You need to login again.', 'Access denied');
        this._router.navigate([`login`]);
      }
    );
  }
}
