import { Injectable } from '@angular/core';

// see http://stackoverflow.com/questions/40760099/how-to-open-new-window-in-new-tab-in-angular2

@Injectable()
export class WindowRef {
  constructor() {}

  getNativeWindow() {
    return window;
  }
}
