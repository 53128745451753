import { map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { User } from '../types/user';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {
  private url = `${environment.token_auth_config.apiBase}/users`;

  public openModal: Subject<string> = new Subject<string>();
  public enteredEmail: string; // only used in signin/up process
  private userDeleted: Subject<string> = new Subject<string>();
  private currentUser: ReplaySubject<User> = new ReplaySubject<User>();

  constructor(private http: HttpClient) {}

  public get getCurrentUser(): Observable<User> {
    return this.currentUser.asObservable();
  }

  public setCurrentUser(value: User): void {
    this.currentUser.next(value);
  }

  public resetCurrentUser(): void {
    this.currentUser.complete();
    this.currentUser = new ReplaySubject<User>(null);
  }

  public getUsers(): Observable<User[]> {
    return this.http.get(`${this.url}`).pipe(map((response: HttpResponse<User[]>) => response['users']));
  }

  public patchUser(user: User): Observable<User> {
    return this.http.patch(`${this.url}/${user.id}`, user).pipe(
      map((response: HttpResponse<User>) => {
        this.setCurrentUser(response['user']);
        return response['user'];
      })
    );
  }

  public get getUserDeleted(): Observable<string> {
    return this.userDeleted.asObservable();
  }

  public setUserDeleted(id: string): void {
    this.userDeleted.next(id);
  }

  public deleteUser(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}`).pipe(
      map(response => {
        this.setUserDeleted(id);
        return response;
      })
    );
  }

  public emailSupport(message: string): Observable<any> {
    return this.currentUser.pipe(
      take(1),
      switchMap(user =>
        this.http.post(`${this.url}/${user.id}/email_support`, { user: message }).pipe(
          map(response => {
            return response;
          })
        )
      )
    );
  }
}
