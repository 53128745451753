import { TemplateItem } from './template-item';

// NOTE - if you add new attributes, also update the convert method in template.service
export class Template {
  constructor() {}

  public id: string;
  public account_id: string;
  public name: string;
  public title: string;
  public info: string;
  public template_items: Array<TemplateItem>;
  public template_type: string;
  public percent_complete = 0; // not stored in api database
  public updated_at: Date;
  public sent_at: Date;
  public printed_at: Date;
  public follow_up_email_opened_at: Date;
  public follow_up_email_clicked_at: Date;
}
