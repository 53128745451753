import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { User } from '../types/user';
import { UserService } from '../services/user.service';
import { AngularTokenService } from 'angular-token';

@Injectable()
export class UserResolver implements Resolve<User> {
  constructor(private userService: UserService, private router: Router, private authService: AngularTokenService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<User> | Observable<never> {
    return this.userService.getCurrentUser.pipe(
      take(1),
      mergeMap((user: User) => {
        if (user) {
          console.log("UserResolver: user is " + user.email);
          return of(user);
        } else {
          return this.authService.validateToken().pipe(
            take(1),
            mergeMap((user: any) => {
              if (user) {
                console.log("UserResolver: user is " + user.email, "(via validate token)");
                this.userService.setCurrentUser(user);
                return of(user);
              }
              else {
                this.router.navigate(['pages/404']);
                return of(null);
              };
            }));
        }
      })
    );
  }

}
