import { ModuleWithProviders } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AngularTokenService } from 'angular-token';
import { ErrorComponent } from './pages/error/error.component';

export const routes: Routes = [
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AngularTokenService]
  },
  { path: 'login', loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule) },
  { path: 'reset', loadChildren: () => import('./auth/reset/reset.module').then(m => m.ResetModule) },
  { path: 'logout', loadChildren: () => import('./auth/logout/logout.module').then(m => m.LogoutModule) },
  { path: 'accept', loadChildren: () => import('./auth/accept/accept.module').then(m => m.AcceptModule) },
  { path: 'register', loadChildren: () => import('./auth/register/register.module').then(m => m.RegisterModule) },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
    canActivate: [AngularTokenService]
  },
  { path: '**', component: ErrorComponent }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload',
  paramsInheritanceStrategy: 'always' // added so pages user data can go everywhere
  // useHash: true
});
