import { switchMap, take, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Template } from '../types/template';
import { UserService } from './user.service';
import { TemplateItem } from '../types/template-item';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { removeTemporaryIds } from '../utils/remove-temporary-ids';
import { AngularTokenService } from 'angular-token';
import { User } from '../types/user';

@Injectable()
export class TemplateService {
  private url = `${environment.token_auth_config.apiBase}/templates`;

  private selectedTemplate = new ReplaySubject<Template>(1);
  private templateForModal = new Subject<Template>();
  private templateDeleted: Subject<string> = new Subject<string>();
  public openNameModal: Subject<string> = new Subject<string>();
  public openPasteModal: Subject<number> = new Subject<number>();
  public userPastedIndex: number = null;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private authService: AngularTokenService
  ) { }

  public get getSelectedTemplate(): Observable<Template> {
    return this.selectedTemplate.asObservable();
  }

  public setSelectedTemplate(template: Template): void {
    this.selectedTemplate.next(template);
  }

  public get getTemplateForModal(): Observable<Template> {
    return this.templateForModal.asObservable();
  }

  public setTemplateForModal(template: Template): void {
    this.templateForModal.next(template);
  }

  public get getTemplateDeleted(): Observable<string> {
    return this.templateDeleted.asObservable();
  }

  public setTemplateDeleted(id: string): void {
    this.templateDeleted.next(id);
  }

  private newTemplate(name: string, accountId: string): Template {
    const template = new Template();
    template.name = name;
    template.account_id = accountId;
    return template;
  }

  getTemplates(filter: string = null): Observable<Template[]> {
    let reqParams = new HttpParams();
    if (filter) reqParams = reqParams.append('filtering', filter);
    return this.http.get(this.url, { params: reqParams }).pipe(
      map(response => {
        return response as Template[];
      })
    );
  }

  getTemplate(id: string): Observable<Template> {
    return this.http.get(`${this.url}/${id}`).pipe(
      map((response) => {
        return response as Template;
      })
    );
  }

  postTemplate(name: string): Observable<Template> {
    return this.userService.getCurrentUser.pipe(
      take(1),
      switchMap(user =>
        this.http
          .post(this.url, this.newTemplate(name, user.account_id))
          .pipe(map((response: HttpResponse<Template>) => response['template']))
      )
    );
  }

  duplicateTemplate(template: Template, name: string, fromChecklist: boolean = false): Observable<any> {
    let url = `${this.url}/${template.id}/duplicate`;
    if (fromChecklist) {
      url += 'checklist';
     }
    return this.userService.getCurrentUser.pipe(
      take(1),
      mergeMap(user =>
        this.http.post(url, this.newTemplate(name, user.account_id)).pipe(
          map(response => {
            return response;
          })
        )
      )
    )
  }

  // We need this method to append _attributes to template_items so that Rails
  // accepts_nested_attributes_for will work
  // ref: https://stackoverflow.com/questions/46853637/rails-5-1-api-how-to-permit-params-for-nested-json-objects-attributes
  private convert(template: Template): Object {
    const out = new Object(); // Object, because we just want a JSON representation
    out['id'] = template.id;
    out['account_id'] = template.account_id;
    out['title'] = template.title;
    out['name'] = template.name;
    out['info'] = template.info;
    out['template_items_attributes'] = template.template_items;
    console.log('out is', out);
    return out;
  }

  patchTemplate(template: Template): Observable<Template> {
    removeTemporaryIds(template);
    console.log('patching template', template)
    return this.http.patch(`${this.url}/${template.id}`, this.convert(template)).pipe(
      map(response => {
        // we need to refresh the user data to get update info about the account and whether they are over their limit
        this.authService.validateToken().subscribe((result) => { this.userService.setCurrentUser(result.data as User); });
        return response['template'];
      })
    );
  }

  postPrintedAt(template: Template): Observable<Template> {
    return this.http.post(`${this.url}/${template.id}/printed`, this.convert(template)).pipe(
      map(response => {
        return response['template'];
      })
    );

  }

  deleteTemplate(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}`).pipe(
      map(response => {
        this.setTemplateDeleted(id);
        return response;
      })
    );
  }

  postTemplateItem(item: TemplateItem): Observable<any> {
    return this.http.post(`${this.url}/${item.template_id}/template_items`, item).pipe(
      map(response => {
        return response['template_item'];
      })
    );
  }

  patchTemplateItem(item: TemplateItem): Observable<any> {
    return this.http.patch(`${this.url}/${item.template_id}/template_items/${item.id}`, item).pipe(
      map(response => {
        return response;
      })
    );
  }

  deleteTemplateItem(item: TemplateItem): Observable<any> {
    return this.http.delete(`${this.url}/${item.template_id}/template_items/${item.id}`).pipe(
      map(response => {
        return response;
      })
    );
  }
}
