import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SelectOptionNumber } from '../types/select-option-number';

@Injectable()
export class DashboardService {
  private checklistId: Subject<Object> = new Subject<Object>();
  private sortChoice: Subject<number> = new Subject<number>();
  private isAllChoice: Subject<boolean> = new Subject<boolean>();
  private isEditingRecipient: Subject<boolean> = new Subject<false>();
  private isDashboardModalOpen: Subject<boolean> = new Subject<false>();

  public sortOptions: SelectOptionNumber[] = [
    { value: 0, name: 'Soonest follow up', disabled: false },
    { value: 1, name: 'Latest follow up', disabled: false },
    { value: 2, name: 'not sorted', disabled: true }, // set by code only, and it's in this position so we can use a separator in the UI
    { value: 3, name: 'Least progress', disabled: false },
    { value: 4, name: 'Most progress', disabled: false }
  ];

  constructor() {}

  public get getEditingChecklistId(): Observable<Object> {
    return this.checklistId.asObservable();
  }

  public setEditingChecklistId(id: string, state: boolean): void {
    const data = new Object({ id: id, state: state });
    this.checklistId.next(data);
  }

  public get getEditingRecipient(): Observable<boolean> {
    return this.isEditingRecipient.asObservable();
  }

  public setEditingRecipient(value: boolean): void {
    this.isEditingRecipient.next(value);
  }

  public get getDashboardModalOpen(): Observable<boolean> {
    return this.isDashboardModalOpen.asObservable();
  }

  public setDashboardModalOpen(value: boolean): void {
    this.isDashboardModalOpen.next(value);
  }

  public get getSortChoice(): Observable<number> {
    return this.sortChoice.asObservable();
  }

  public setSortChoice(value: number): void {
    this.sortChoice.next(value);
  }

  public get getIsAllChoice(): Observable<boolean> {
    return this.isAllChoice.asObservable();
  }

  public setIsAllChoice(value: boolean): void {
    this.isAllChoice.next(value);
  }
}
