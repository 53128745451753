
import * as packageJSON from '../../package.json';

export const environment = {
  version: packageJSON.version,
  name: packageJSON.name,
  production: true,
  angular_env: 'production',
  mixpanel_token: 'd1558fb540e7544e99923340c92b0ba7',
  token_auth_config: {
    apiHost: 'https://api.checkwiz.com',
    apiBase: 'https://api.checkwiz.com/api',
    registerAccountCallback: 'https://app.checkwiz.com/login',
    resetPasswordCallback: 'https://app.checkwiz.com/reset',
    signInRedirect: 'login'
  }
};
