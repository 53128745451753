import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Reminder } from '../types/reminder';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReminderService {
  private url = `${environment.token_auth_config.apiBase}/reminders`;

  constructor(private http: HttpClient) {}

  // note - no get needed, as reminder is returned embedded in User object

  patchReminder(reminder: Reminder): Observable<Reminder> {
    return this.http.patch(`${this.url}/${reminder.id}`, { reminder: reminder }).pipe(
      map(response => {
        return response['reminder'] as Reminder;
      })
    );
  }
}
