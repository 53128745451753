import { Template } from '../types/template';

export const removeTemporaryIds = (template: Template) => {
  template.template_items = template.template_items?.map((item, index) => {
    item.sort = index;
    if (item.id?.includes('temp')) {
      item.id = null; // it's a new item, let API create the id
    }
    return item;
  })
}
