import { from as observableFrom, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

/**
 * Async modal dialog service
 * DialogService makes this app easier to test by faking this service.
 */
@Injectable()
export class DialogService {
  // constructor(public _modal: any) {}
  // /**
  //  * Ask user to confirm an action. `message` explains the action and choices.
  //  * Returns observable resolving to `true`=confirm or `false`=cancel
  //  */
  // confirm(message?: object): Observable<boolean> {
  //   const confirmation = this._modal
  //     .confirm()
  //     .isBlocking(true)
  //     .size('lg')
  //     .title(message['title'] || 'Please confirm')
  //     .body(message['body'] || 'Click OK to confirm')
  //     .okBtn(message['okBtn'] || 'OK')
  //     .okBtnClass(message['okBtnClass'] || 'btn btn-primary')
  //     .open()
  //     .result.then(result => {
  //       return true;
  //     })
  //     .catch(error => {
  //       return false;
  //     });
  //   return observableFrom(confirmation) as Observable<boolean>;
  // }
}
