import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { User } from '../types/user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class InviteService {
  private url = `${environment.token_auth_config.apiBase}/auth/invitation`;
  private batchUrl = `${environment.token_auth_config.apiBase}/batch_invites`;

  constructor(private http: HttpClient) {}

  patchInvite(data: any): Observable<User> {
    return this.http.patch(this.url, { user: data }).pipe(
      map(response => {
        return response['user'];
      })
    );
  }

  postInvite(email: string): Observable<User> {
    return this.http.post(this.url, { user: { email: email } }).pipe(
      map(response => {
        return response['user'];
      })
    );
  }

  public inviteUsers(emails: Object): Observable<any> {
    return this.http.post(this.batchUrl, emails).pipe(
      map(response => {
        return response;
      })
    );
  }

  // Note this method is not using auth or /api server endpoint - since it does not apply to logged in users
  public resendConfirmation(email: string): Observable<any> {
    return this.http.post(`${environment.token_auth_config.apiHost}/user/resend_confirmation`, { email: email }).pipe(
      map(response => {
        return response;
      })
    );
  }
}
