import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Card } from '../types/card';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { AngularTokenService } from 'angular-token';
import { User } from '../types/user';

@Injectable()
export class CardService {
  private url = `${environment.token_auth_config.apiBase}/cards`;

  private newCardDone = new BehaviorSubject<string>(null);
  private dueInSaved = new Subject<Card>();
  private followUpSaved = new Subject<Card>();
  private percentSaved = new Subject<Card>();
  private deletedId = new Subject<string>();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private authService: AngularTokenService
  ) { }

  public get getNewCardDone(): Observable<string> {
    return this.newCardDone.asObservable();
  }

  public setNewCardDone(cardId: string): void {
    this.newCardDone.next(cardId);
  }

  public get getDeletedId(): Observable<string> {
    return this.deletedId.asObservable();
  }

  public setDeletedId(id: string): void {
    this.deletedId.next(id);
  }

  public get getDueInSaved(): Observable<Card> {
    return this.dueInSaved.asObservable();
  }

  public setDueInSaved(card: Card): void {
    this.dueInSaved.next(card);
  }

  public get getFollowUpSaved(): Observable<Card> {
    return this.followUpSaved.asObservable();
  }

  public setFollowUpSaved(card: Card): void {
    this.followUpSaved.next(card);
  }

  public get getPercentSaved(): Observable<Card> {
    return this.percentSaved.asObservable();
  }

  public setPercentSaved(card: Card): void {
    this.percentSaved.next(card);
  }

  getCards(isMainDashboard: Boolean, filter: string = null): Observable<Card[]> {
    let reqParams = new HttpParams();
    if (filter) reqParams = reqParams.append('filtering', filter);
    // we only get incomplete cards for the dashboard, so set filter to false
    reqParams = reqParams.append('completed', Number(!isMainDashboard).toString());
    return this.http.get(this.url, { params: reqParams }).pipe(
      map(response => {
        return response['cards'];
      })
    );
  }

  getCard(id: string): Observable<Card> {
    return this.http.get(`${this.url}/${id}`).pipe(
      map(response => {
        return response['card'];
      })
    );
  }

  postCard(card: Card): Observable<Card> {
    return this.http.post(this.url, card).pipe(
      map(response => {
        return response['card'];
      })
    );
  }

  patchCard(card: Card): Observable<Card> {
    return this.http.patch(`${this.url}/${card.id}`, card).pipe(
      map(response => {
        return response['card'];
      })
    );
  }

  deleteCard(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}`).pipe(
      map(response => {
        // we need to refresh the user data to get update info about the account and whether they are over their limit
        this.authService.validateToken().subscribe((result) => { this.userService.setCurrentUser(result.data as User); });
        this.setDeletedId(id);
        return response;
      })
    );
  }

  addChecklist(id: string): Observable<any> {
    return this.http.post(`${this.url}/${this.getNewCardId()}/checklist`, { template_id: id }).pipe(
      map(response => {
        return response;
      })
    );
  }

  addRecipient(id: string): Observable<any> {
    return this.http.post(`${this.url}/${this.getNewCardId()}/recipient`, { customer_id: id }).pipe(
      map(response => {
        return response['card'];
      })
    );
  }

  private getNewCardId(): string {
    const newCard: Card = JSON.parse(localStorage.getItem('card'));
    return newCard.id;
  }
}
