import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ApiErrorService {
  constructor(private _toastrService: ToastrService) {}

  // TODO change erros: any to HttpErrorResponse
  public notifyErrors(errorResponse: any): void {
    let result = null;

    if (errorResponse.hasOwnProperty('errors')) {
      result = errorResponse['errors'];
    }
    if (errorResponse.hasOwnProperty('error')) {
      result = errorResponse['error'];
    }
    if (result.hasOwnProperty('error')) {
      // special case for stripe checkout, see the api resposne (probably should be changed)
      this._toastrService.error('Please contact support', result['error']['message']);
    } else {

      if (result) {
        if (result['errors'].hasOwnProperty('full_messages')) {
          result['errors']['full_messages'].forEach(error => {
            this._toastrService.error(error, 'Failure');
          });
        } else {
          console.log('the error is', result);
          result['errors'].forEach(error => {
            if (error.hasOwnProperty('message')) this._toastrService.error(error['message'], 'Failure');
            else this._toastrService.error(error, 'Failure');
          });
        }
      } else {
        console.log("errorResponse is", errorResponse);
        this._toastrService.error("It seems the server is down, please contact support", "Failure");
      }
    }
  }
}
